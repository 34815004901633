.bookingsContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

.bookingSpotCard {
    display: flex;
    width: 24%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.bookingSpotPrice {
    height: fit-content;
    text-align: center;
}

.updateBookingButton {
    width: fit-content;
    height: fit-content;

}
.updateBookingButton > button {
    background-color: gray;
    color: white;
    box-shadow: 2px 2px 2px black;
}

.deleteBookingButton > button {
    background-color: gray;
    color: white;
    box-shadow: 2px 2px 2px black;
}

.deleteBookingButton{
    width: fit-content;
    height: fit-content;

}

.manageBookingButtons{
    display: flex;
    gap: 10px;
    width: fit-content;
    justify-content: center;
}

.bookingSpotImage{
    display: flex;
    justify-content: center;

    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

@media screen and (max-width: 573px) {
    .bookingsContainer{
        flex-direction: column;
        align-items: center;
    }
    .bookingSpotCard {
        width: 100%;
    }
    .bookingSpotImage{
        width: 100%;
    }
}
@media screen and (min-width: 574px) and (max-width: 800px) {

    .bookingSpotCard {
        width: 49%;
    }
}
@media screen and (min-width: 801px) and (max-width: 1000px) {

    .bookingSpotCard {
        width: 30%;
    }
}
