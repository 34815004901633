#updateCountry {
    width: 100%;
}

#updateAddress {
    width: 100%;
}

#updateCity {
    width: 97%;
}
#updateState {
    width: 100%;
}

#updateLat {
    width: 97%;
}

#updateLng {
    width: 100%;
}

#updateTitle {
    width: 100%;
}

#updatePrice {
    width: 100%;
}

#updateSpotButton{
    padding: 4px 30px;
    color: white;
    background-color: red;
    box-shadow: 3px 2px 2px black;
}

#updateTextArea{
    width: 100%;
    height: 20%;
}

.userInput {
    border: 2px solid black;
}

@media screen and (max-width: 900px){
     .createEditSpot{
        display: flex;
        padding: 0px;
        width: 100%;
    }
}
