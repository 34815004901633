#imageClick:hover {
    box-shadow: 2px 3px 5px 2px grey;
    cursor: pointer;
    transform: scale(1.01);
}
.pictures {
    display: flex;
    gap: 5px;
}

#previewImage >img {
    width: 100%;
    height: 334.33px;
    object-fit: cover;
}

.nonPreviewPics > img {
    width: 49%;
    height: 163.81px;
    object-fit: cover;
}
.name-location > p {
    text-align: start;
}

.previewImage > img {
    width: 100%;
    height: 334.33px;
    object-fit: cover;
}
#previewImage {
    width: 50%;
    height: 100%
}

.nonPreviewPics {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 50%;
    height: 100%;
    gap: 5px;
}

.nonPreviewPics > img {
    width: 49%;
    height: 163.81px;
    object-fit: cover;
}

.Host-Info{
    display: flex;
    flex-direction: column;
    width: 50%
}

#top {
    width:fit-content;
    display:flex;
    align-items: center;
    border: 1px solid black;
}

.topSpanDiv{
    display:flex;
    justify-content: end;

}

.bottomSpanDiv {
    display:flex;
    justify-content: flex-start;
}

#bottom {
    text-align: start;
}

.bottomstuff{
    display:flex;
    align-items: center;
    gap: 3px;
}

.textInTheBox{
    display: flex;
    align-items: center;
    gap:3px;
}

.smallBox {
    width: 100%;
}

.bigolDiv{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
}


#deleteReviewButton {
    background-color: red;
    color: white;
}

#keepReviewButton {
    background-color: darkgray;
    color: white;
}

.reviewInfo{
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    width: 35%;
    border: 2px solid black;
    margin-top: 15px;
    height: fit-content;
    border-radius: 13px;
}

.Host-Info{
    width: 60%;
}

.smallReviewInfoContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 20%;
    width:100%;
}

.reserveButtonContainer > button {
    padding: 8px 90px;
    color: white;
    background-color: red;
    box-shadow: 3px 2px 2px black;
}
.reserveButtonContainer > button:hover {
    cursor: pointer;
}


label {
    text-decoration: none;
    color: black;
}


#pricePerNight{
    width: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
    padding-right: 80px;
    gap: 4px;
}

#reviewInfo {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}

.reserveButtonContainer{
    padding-bottom: 15px;
}

#spotDescription {
    width: 95%;
    word-break: break-all;
}

.topHalf {
    border-bottom: 1px solid black;
}

.everythingWrapper {
    padding: 0 30px;
}

#username{
    font-size: 22px;
    font-weight: bolder;
}

#formattedDate {
    font-style: italic;
}

.addReviewModal > button {
    background-color: gray;
    color: white;
    box-shadow: 2px 2px 2px black;
}

.addReviewModal > button:hover {
    cursor: pointer;
}

.deleteReviewModal > button {
    background-color: gray;
    color: white;
    box-shadow: 2px 2px 2px black;
}

.deleteReviewModal > button:hover{
    cursor: pointer;
}

.updateReviewButtonSpot > button {
    background-color: gray;
    color: white;
    box-shadow: 2px 2px 2px black;
}

.reviewButtonsSpot{
    display: flex;
    width: 100%;
    align-items: center;
}

.user-reviews {
    display: flex;
    flex-direction: column;
    align-items: start;
    border-bottom: 1px solid black;
}

@media screen and (max-width: 573px) {
    .everythingWrapper{
        padding: 0px;
    }

    .name-location {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .everythingWrapper{
        display: flex;
    }
    .topHalf{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .pictures {
        display: flex;
        flex-direction: column;
    }
    #previewImage, .nonPreviewPics > img{
        width: 100%;
        object-fit: fill;
    }

    .nonPreviewPics {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content
    }
    .bigOlDiv{
        display: flex;
        flex-direction: column;
    }
    .Host-Info {
        width: 100%;
        text-align: center;
    }
    .reviewInfo {
        width: 100%;
        justify-content: space-between;
    }
    .reserveButtonContainer {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .reserveButtonContainer > button {
       width: 80%;
       display: flex;
       justify-content: center;
    }
    .bottom-half {
        display: flex;
        flex-direction: column ;
        width: 100%;
    }
    .user-reviews {
        width: 100%;
    };

    #pricePerNight{
        width: 100px;
    }
}

@media screen and (min-width: 574px) and (max-width: 902px){
    .pictures {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .previewImage > img, #previewImage, .nonPreviewPics{
        width: 100%;
    }
    #pricePerNight{
        display: flex;
        justify-content: start;
        font-size: 1rem;
        padding: 0px;
        width: fit-content
    }
    .reserveButtonContainer {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .reserveButtonContainer > button {
        display: flex;
        justify-content: center;
        width: 50%;
    }
    #reviewInfo {
        display: flex;
        justify-content: end;
        width: 50%;
        object-fit: cover;
    }
    .reviewInfo {
        width: 100%;
    }
    .Host-Info{
        width: 100%;
    }
    .Host-Info > h2{
        text-align: center    ;
    }
    .smallReviewInfoContainer {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
}

@media screen and (min-width: 1300px){
    .pictures {
        height: 600px;
    }
    #previewImage > img {
        height:100%;
    }
    .nonPreviewPics > img {
        height:49%;
    }
}
