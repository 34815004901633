#spotsContainerManageSpot {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-evenly;
}


.cardHolderWithButtons {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 302px;
    width: 23%;

}

.spotCardManageSpot{
    text-decoration: none;
    color: black;
    font-weight: 500;
}

#manageSpotReview {
    display: flex;
    align-items: center;
}

.deleteSpotModal {
    width: 100%;
    padding: 0px 5px;
}

.deleteSpotH1 {
    text-align: center;
}

.manageSpotButtons{
    list-style-type: none;
    flex:1;
    width: fit-content;
}

.deleteSpotButtons{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
}

.deleteSpotButtons > button {
    padding: 5px 35px;
    border-radius: 5px;
    font-weight: bolder;
}
#yesDelete {
    background-color: red;
    color: white;
}
.deleteSpotButton > button{
    color: white;
    background-color: darkgray;
    box-shadow: 4px 3px 2px black;
}

.updateDeleteButtons {
    display: flex;
    justify-self: flex-end;
    align-items: end;
    width: 100%;
    gap: 1px;
}

.updateASpotButton {
    color: white;
    background-color: darkgray;
    box-shadow: 4px 3px 2px black;
    height: fit-content;
}

.updateASpotButton:hover {
    cursor: pointer;
}

#deleteSpotTitle{
    text-align: center;
    margin: 7px 0px 15px 0px;
}

#deleteSpotDiv {
    width: 300px;
    height: 200px;
    padding: 0px 10px;
}

#deleteOrKeep{
    display: flex;
    gap: 8px;
    flex-direction: column;
}

#deleteReviewButton {
    height: 30px;
    margin: 0px 5px;
    box-shadow: 3px 3px 2px black;
}

#keepReviewButton {
    height: 30px;
    margin: 0px 5px;
    box-shadow: 3px 3px 2px black;
}

.spotPic {
    width: 100%;
    /* border: 1px solid red; */
}

#manageSpotPreviewImage{
    width: 100%;
    height: 195.19px;
}

#yesDelete:hover {
    cursor: pointer;
}

#noDelete:hover {
    cursor: pointer;
}


@media screen and (max-width: 573px) {
    #spotsContainerManageSpot {
        flex-direction: column;
        height: 100%;
    }
    #manageSpotPreviewImage{
        width: 100%;
    }
    .spotPic{
        width: 100%;
    }
    .cardHolderWithButtons{
        width: 100%;
    }
}

@media screen and (min-width: 574px) and (max-width: 800px) {
    #spotsContainerManageSpot {
        flex-direction: row;

        height: 100%;
    }
    #manageSpotPreviewImage{
        width: 100%;
    }
    .cardHolderWithButtons{
        width: 49%;
    }
}
@media screen and (min-width: 801px) and (max-width: 1000px) {
    #spotsContainerManageSpot {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        align-items: start;
    }
    #manageSpotPreviewImage{
        width: 100%;
    }
    .cardHolderWithButtons{
        width: 32%;

    }

}
