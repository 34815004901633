#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.88);
  }

  #image-modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0);
  }

  #modal-content {
    position: absolute;
    background-color: white;
  }
  #image-modal-content {
    position: absolute;
    background-color :transparent;
  }

  .imageModalContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position:relative;
  }

  .fa-chevron-right, .fa-chevron-left{
    color: white;
    cursor: pointer;
    font-size: 30px;
  }

  .fa-chevron-left:hover, .fa-chevron-right:hover{
    color:grey;
  }

  .zoomedInImage{
    width:90%;
  }

  .testingthisout {
    display: flex;
    width: 100%;
  }
  .container1 {
    display: flex;
    width: 50%;
    justify-content: end;
  }
  .container2 {
    width: 45%;
    display: flex;
    justify-content: end;
  }

  .fa-times-circle {
    display: flex;
    color: white;
    cursor: pointer;
    font-size: 30px;
    width: 20px;
  }

  .fa-times-circle:hover {
    color: grey;
  }
