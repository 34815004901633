.DeleteReviewModal{
    margin: 5px;
}

.DeleteReviewModal > h1 {
    text-align: center;
}

.deleteReviewButtons {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.deleteReviewButtons > button {
    background-color: gray;
    color: white;
    box-shadow: 2px 2px 2px black;
}
