label {
    color: blue;
}

.submitDemoUserButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loginButton {
    margin-top: 5px;
    width: 300px;
    background-color: rgb(246, 68, 68);
    color: white;
    height: 30px;
    box-shadow: 5% 20% 20% 5%;
}

.demoUserButton {
    width: 88%;
    background-color: white;
    border: none;
    text-decoration: underline;
}

.loginmodal {
    width: 90%;
}

.usernameoremail{
    width: 100%;
}
.loginH1{
    margin: 15px 0px 5px 0px;
}
#loginUsername{
    width: 88%;
}
#loginPassword{
    width: 88%;

}

#usernameOrPassword{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#loginContent {
    width: 100%;
    padding: 10px 20px;
}

#loginLoginErrors{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#modal-content {
    border-radius: 12px;
}
