.updateBookingModal{
    display: flex;
    flex-direction: column;
    margin: 5px 10px;
}

.datesInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.startInput{
    display: flex;
}

.endInput{
    display: flex;
}

.modalH1{
    text-align: center;
}

.submitButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.updateBookingButtonModal{
    background-color: gray;
    color: white;
    padding: 2px 25%;
    border-radius: 5px;
    box-shadow: 2px 2px 2px black;
    margin-bottom: 5px;
}
