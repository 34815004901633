body {
font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.cardHolder{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px
}
.spotCard {
    width: 24%;
    gap: 5px;
    height:100%;
    text-decoration: none;
    color: black;
    font-weight: 500;
}

.spotCard:hover{
    border: 1px solid rgb(195, 182, 182);
}

.previewImage {
    width: 100%;
    border-radius: 15px;
    height: 225px;
}

.spotInfo{
    width: 100%;
    display: flex;
}

.leftSide {
    width: 50%;
}

.cityState > p {
    text-align: start;
}

.rightSide {
    display: flex;
    width: 50%;
}
span {
    display:flex;
    width: 100%;
    gap: 5px;
    justify-content: end;
}
.userButton {
    display:flex;
    justify-content: end;
}

ul {
    list-style-type: none;
    text-align: end;
}

.userButtonLi{
    width: fit-content
}
.upperBarDiv {
    display:flex;
    justify-content: end;
}
@media screen and (min-width: 1300px) {
    .previewImage{
        height: 350px;
    }
}
@media screen and (min-width: 801px) and (max-width: 1023px){
    .spotCard{
        width: 32%;
        height: 100%;
    }
    .previewImage {
        object-fit: cover;
        height: 325px;
    }
}
@media screen and (max-width: 573px) {
    .cardHolder{
        flex-direction: column;
        flex-wrap: wrap;
    }
    .spotCard{
        width: 100%;
        height: fit-content;
    }
    .previewImage {
        object-fit: cover;
        height: 325px;
    }
}

@media screen and (min-width: 574px) and (max-width: 800px){
    .spotCard{
        width: 49%;
        height: 100%;
    }
    .previewImage {
        object-fit: cover;
        height: 325px;
    }
}


@media screen and (max-width: 400px){
    .cardHolder{
        flex-direction: column;
    }

    .spotCard{
        width: 100%;
        height: 100%;
    }
    .previewImage {
        object-fit: cover;
        height: 250px;
    }
}
