#createReviewModalBox {
    width: 350px;
    height: fit-content;
    margin: 20px 10px;
}

#createReviewTitle {
    text-align: center;
}

#createStars {
    display: flex;
}

#starDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%
}

#submitReviewButton {
    background-color: red;
    color: white;
    width: 100%;
    height: 50%;
    box-shadow: 3px 3px 2px black;
}

#submitReviewButton:hover {
    cursor: pointer;
}


#submitReviewButton:disabled {
    background-color: white;
    cursor:initial;
    color:gray;
    width: 100%;
    height: 50%;
    box-shadow: 3px 3px 2px gray;
}

#buttonDiv {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#modal-content {
    background-color: rgba(236, 240, 255, 0.918);
}
