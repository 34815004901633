#logo {
    height: 75px;
    width: 75px;
    object-fit: cover;
}

.hidden {
    visibility: hidden;
}

#navButton {
    display: flex;
    gap: 8px;
    padding: 5px 13px;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}

#manageBookings {
    text-decoration: none;
    color: black;
    text-align: center;
}

#manageBookingsLi {
    border-bottom: 1px solid black;
    padding: 5px 0px;
    text-align: center;
}

#navContainer {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;
}

#createANewSpotNav {
    text-decoration: none;
    color: teal;
    font-weight: bold;
}

.socialsButton {
    display: flex;
    justify-content: center;
}

.socialsButton > button {
    font-weight: bold;
    background-color: transparent;
    border: none;
    color: teal;
}

#helloButton {
    text-decoration: none;
    color: black;
}

.profile-dropdown {
    border: 1px solid black;
    position: absolute;
    right: 1%;
    padding: 0px;
    background-color: white;
}

#helloUser {
    border-bottom: 1px solid black;
    margin: 2px 0px
}

#helloUserSpots {
    border-bottom: 1px solid black;
    padding: 5px 0px;

}

.searchbarcontainer {
    display: flex;
    justify-content: center;
    width: 70%;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
}

.createSpotAndUser {
    display: flex;
    justify-content: end;
    width: 70%;
    gap: 20px;
}

.searchBar {
    width: 90%;
    padding: 5px;
    border: none;
    position: relative;
}

.fa-search {
    width: 100%;
}

.searchBar:focus {
    outline: none;
}

.searchButton {
    border: none;
    background-color: white;
    width: 10%;
    cursor: pointer;
}

#logoutButton {
    padding: 5px;
    margin: 3px;
    text-align: center;
    border: 2px solid black;
    border-radius: 10px;
    color: white;
    background-color: gray;
}

#logoutButton:hover {
    cursor: pointer;
}

#logoutButtonDiv {
    display: flex;
    justify-content: center;
}

.userInfoLi {
    text-align: center;
    word-wrap: break-word;
}

@media screen and (max-width: 573px) {
    #navContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid gray;
    }
    .createSpotAndUser {
        display: flex;
        justify-content: space-between;
    }
    .profile-dropdown {
        right: 10%;
    }
}

.loggedOut > li {
    margin: 5px 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;

}
