#signContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 400px;
    padding: 10px 10px;
}

#signupTitle {
    text-align: center;
    margin: 5px 0px;
}

.SignupLabel {
    display: flex;
    flex-direction: column;
}

#signupButtonDiv {
    margin-top: 10px;
    text-align: center;
}

#signupButton {
    display: inline-block;
    padding: 5px;
    margin: 3px;
    text-align: center;
    border: 2px solid black;
    border-radius: 10px;
    color: white;
    background-color: gray;
}
